<template>
    <div class="main-card card pa-0 ma-0">
        <div class="card-body pa-0">
            <b-tabs :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" content-class="mt-3">
            <b-tab :title="this.$t('accounting.lang_paymentOptions')"><GlobalePaymentPermissionComponent/></b-tab>
            <b-tab v-if="$store.getters['permissions/checkModule'](42)" :title="this.$t('accounting.lang_additionalPaymentMethodsH4')"><payments-component/></b-tab>
            <b-tab v-if="$store.getters['permissions/checkModule'](36)" :title="this.$t('settings.lang_nav_ecterminal')"><ECTerminalsComponent/></b-tab>
            <b-tab :title="this.$t('accounting.lang_invoices')"><InvoicesComponent/></b-tab>
            <b-tab :title="this.$t('accounting.lang_cashPayment')"><CashPaymentComponent/></b-tab>
            <b-tab :title="this.$t('accounting.lang_ecPayment')"><ECSettingsComponent></ECSettingsComponent></b-tab>
            <b-tab :title="this.$t('accounting.lang_paypalPayment')"><PaypalSettingsComponent/></b-tab>
            <b-tab v-if="$store.getters['permissions/checkModule'](71)" :title="this.$t('accounting.lang_stripePayment')"><StripeSettingsComponent/></b-tab>
            <b-tab :title="this.$t('accounting.lang_secupayPayment')"><SecupaySettingsComponent/></b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import GlobalePaymentPermissionComponent from "./GlobalePaymentPermissionComponent";
    import PaymentsComponent from "./PaymentsComponent";
    import ECTerminalsComponent from "./ECTerminalsComponent";
    import InvoicesComponent from "./InvoicesComponent";
    import CashPaymentComponent from "./CashPaymentComponent";
    import ECSettingsComponent from "@/components/settings/sysetmdata/Payment/ECSettingsComponent";
    import PaypalSettingsComponent from "@/components/settings/sysetmdata/Payment/PaypalSettingsComponent";
    import SecupaySettingsComponent from "./SecupaySettingsComponent";
    import StripeSettingsComponent from "./StripeSettingsComponent";

    export default {
        name: "PaymentSettingsComponent",
        components: {
          StripeSettingsComponent,
          SecupaySettingsComponent,
          PaypalSettingsComponent,
          ECSettingsComponent,
            CashPaymentComponent,
            InvoicesComponent, ECTerminalsComponent, PaymentsComponent, GlobalePaymentPermissionComponent}
    }
</script>

<style scoped>

</style>